// fragment GatsbyImageSharpFluid
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-transformer-sharp/src/fragments.js

const image = props => `
  caption
  created_at
  name
  updated_at
  url
  ${
    props.queryBy === "gatsby"
      ? `
        file {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              presentationWidth
            }
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              width: 1920
              aspectRatio: 1.78
            )
          }
        }
      `
      : "formats"
  }
`

const color = `
  background
  titleColor
  subtitleColor
`

const block = `
  center
  displayCaption
  displayDescription
  displayImage
  displayLink
  displayOverlay
  displaySubtitle
  displayTitle
  imagePosition
  imageRatio
  imageWidth
`

// ======================================================================
// COLLECTIONS TYPES
// ======================================================================

const metadata = props => `
  __typename
  id
  slug
  created_at
  updated_at
  title
  subtitle
  description
  image {
    ${image({ ...props })}
  }
`

const page = props => `
  ${metadata({ ...props })}
  displayCover
  displayMenu
  menu
  order
  ${props.queryBy === "gatsby" ? "paths" : "#undefined"}
`

const document = props => `
  ${metadata({ ...props })}
  attachment {
    url
    ${
      props.queryBy === "gatsby"
        ? `
        file {
          publicURL
        }
      `
        : "#undefined"
    }
  }
  ${
    props.nested > 0
      ? `
    ${locations({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const article = props => `
  ${metadata({ ...props })}
  date
  ${
    props.nested > 0
      ? `
    ${flexibleContent({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const location = props => `
  ${metadata({ ...props })}
  open
  longitude
  latitude
  ${
    props.nested > 0
      ? `
      ${events({ ...props, nested: props.nested - 1 })}
      ${taxons({ ...props, nested: props.nested - 1 })}
      ${documents({ ...props, nested: props.nested - 1 })}
      ${environments({ ...props, nested: props.nested - 1 })}
      ${curators({ ...props, nested: props.nested - 1 })}
      ${partners({ ...props, nested: props.nested - 1 })}
      ${managers({ ...props, nested: props.nested - 1 })}
      ${owners({ ...props, nested: props.nested - 1 })}
      ${municipalities({ ...props })}
    `
      : "#undefined"
  }
`

const event = props => `
  ${metadata({ ...props })}
  date
  duration
  price
  information
  ${
    props.nested > 0
      ? `
    ${locations({ ...props, nested: props.nested - 1 })}
    ${programs({ ...props, nested: props.nested - 1 })}
    ${organizers({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const entity = props => `
  ${metadata({ ...props })}
  avatar {
    ${image({ ...props })}
  }
  ${
    props.nested > 0
      ? `
    ${events({ ...props, nested: props.nested - 1 })}
    ${curatorLocations({ ...props, nested: props.nested - 1 })}
    ${partnerLocations({ ...props, nested: props.nested - 1 })}
    ${managerLocations({ ...props, nested: props.nested - 1 })}
    ${ownerLocations({ ...props, nested: props.nested - 1 })}
    ${parentEntities({ ...props, nested: props.nested - 1 })}
    ${childEntities({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const environment = props => `
  ${metadata({ ...props })}
  ${
    props.nested > 0
      ? `
    ${locations({ ...props, nested: props.nested - 1 })}
    ${taxons({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const program = props => `
  ${metadata({ ...props })}
  avatar {
    ${image({ ...props })}
  }
  ${
    props.nested > 0
      ? `
    ${events({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const taxon = props => `
  ${metadata({ ...props })}
  taxref
  ${
    props.nested > 0
      ? `
    ${locations({ ...props, nested: props.nested - 1 })}
    ${environments({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const municipality = props => `
  ${metadata({ ...props })}
  insee
  department {
    ${department({ ...props })}
  }
  ${
    props.nested > 0
      ? `
    ${locations({ ...props, nested: props.nested - 1 })}
    `
      : "#undefined"
  }
`

const department = props => `
  ${metadata({ ...props })}
  insee
`

// ======================================================================
// BLOCKS TYPES
// ======================================================================

const blockSection = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockSection {
    __typename
    anchor
    display
    title
    intro
    padding
    type {
      layout
      width
      height
    }
    color {
      ${color}
    }
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockCustom = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockCustom {
    __typename
    body
    description
    displayBlock
    linkLabel
    subtitle
    title
    url
    image {
      ${image({ ...props })}
    }
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockPage = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockPage {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    pagesSort
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
    pages {
      ${page({ ...props })}
      parentPages {
        ${page({ ...props })}
        parentPages {
          ${page({ ...props })}
        }
      }
    }
    fromParentPages {
      childPages {
        ${page({ ...props })}
        parentPages {
          ${page({ ...props })}
          parentPages {
            ${page({ ...props })}
          }
        }
      }
    }
    fromChildPages {
      parentPages {
        ${page({ ...props })}
        parentPages {
          ${page({ ...props })}
          parentPages {
            ${page({ ...props })}
          }
        }
      }
    }
  }
`

const blockImage = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockImage {
    __typename
    displayBlock
    linkLabel
    reverseOrder
    imagesSort
    images {
      ${image({ ...props })}
    }
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }    
  }
`

const blockVideo = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockVideo {
    __typename
    code
    description
    displayBlock
    linkLabel
    platform
    subtitle
    title
    image {
      ${image({ ...props })}
    }
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }    
  }
`

const blockDocument = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockDocument {
    __typename
    displayBlock
    linkLabel
    reverseOrder
    fullCollection
    documentsSort
    ${documents({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockArticle = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockArticle {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    articlesSort
    ${articles({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockLocation = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockLocation {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    locationsSort
    ${locations({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }        
  }
`

const blockEvent = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockEvent {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    eventsSort
    ${events({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockTaxon = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockTaxon {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    taxonsSort
    ${taxons({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockEnvironment = props => `
  ... on ${
    props.queryBy === "gatsby" ? "Strapi_" : ""
  }ComponentBlockEnvironment {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    environmentsSort
    ${environments({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const blockEntity = props => `
  ... on ${props.queryBy === "gatsby" ? "Strapi_" : ""}ComponentBlockEntity {
    __typename
    displayBlock
    itemsLimit
    linkLabel
    reverseOrder
    fullCollection
    entitiesSort
    ${entities({ ...props, nested: props.nested - 1 })}
    blockType {
      ${block}
    }
    blockColor {
      ${color}
    }
  }
`

const flexibleContent = props => `
  flexibleContent {
    ${blockSection({ ...props })}
    ${blockCustom({ ...props })}
    ${blockPage({ ...props })}
    ${blockImage({ ...props })}
    ${blockVideo({ ...props })}
    ${blockDocument({ ...props })}
    ${blockArticle({ ...props })}
    ${blockLocation({ ...props })}
    ${blockEvent({ ...props })}
    ${blockTaxon({ ...props })}
    ${blockEnvironment({ ...props })}
    ${blockEntity({ ...props })}
  }
`

// ======================================================================
// COLLECTIONS ITEMS
// ======================================================================

const pages = props => `
  pages {
    ${page({ ...props })}
    parentPages {
      ${page({ ...props })}
      parentPages {
        ${page({ ...props })}
      }
    }
    ${flexibleContent({ ...props })}
  }
`

const documents = props => `
  documents(sort: "title") {
    ${document({ ...props })}
  }
`

const articles = props => `
  articles(sort: "date:DESC") {
    ${article({ ...props })}
  }
`

const locations = props => `
  locations(sort: "title") {
    ${location({ ...props })}
  }
`

const curatorLocations = props => `
  curatorLocations(sort: "title") {
    ${location({ ...props })}          
  }
`

const partnerLocations = props => `
  partnerLocations(sort: "title") {
    ${location({ ...props })}          
  }
`

const managerLocations = props => `
  managerLocations(sort: "title") {
    ${location({ ...props })}          
  }
`

const ownerLocations = props => `
  ownerLocations(sort: "title") {
    ${location({ ...props })}          
  }
`

const events = props => `
  events(sort: "date:DESC") {
    ${event({ ...props })}
  }
`

const entities = props => `
  entities(sort: "title") {
    ${entity({ ...props })}
  }
`

const parentEntities = props => `
  parentEntities(sort: "title") {
    ${entity({ ...props })}
  }
`

const childEntities = props => `
  childEntities(sort: "title") {
    ${entity({ ...props })}
  }
`

const curators = props => `
  curators(sort: "title") {
    ${entity({ ...props })}
  }
`

const partners = props => `
  partners(sort: "title") {
    ${entity({ ...props })}
  }
`

const managers = props => `
  managers(sort: "title") {
    ${entity({ ...props })}
  }
`

const owners = props => `
  owners(sort: "title") {
    ${entity({ ...props })}
  }
`

const organizers = props => `
  organizers(sort: "title") {
    ${entity({ ...props })}
  }
`

const environments = props => `
  environments(sort: "title") {
    ${environment({ ...props })}
  }
`

const programs = props => `
  programs(sort: "title") {
    ${program({ ...props })}
  }
`

const taxons = props => `
  taxons(sort: "title") {
    ${taxon({ ...props })}
  }
`

const municipalities = props => `
  municipalities(sort: "title") {
    ${municipality({ ...props })}
  }
`

const departments = props => `
  departments(sort: "title") {
    ${department({ ...props })}
  }
`

// ======================================================================
// DEFAULT PAGES
// ======================================================================

const homePage = props => `
  homePage {
    id
  }
`

const documentsPage = props => `
  documentsPage {
    id
  }
`

const articlesPage = props => `
  articlesPage {
    id
  }
`

const locationsPage = props => `
  locationsPage {
    id
  }
`

const eventsPage = props => `
  eventsPage {
    id
  }
`
const entitiesPage = props => `
  entitiesPage {
    id
  }
`
const environmentsPage = props => `
  environmentsPage {
    id
  }
`
const taxonsPage = props => `
  taxonsPage {
    id
  }
`
const programsPage = props => `
  programsPage {
    id
  }
`
const municipalitiesPage = props => `
  municipalitiesPage {
    id
  }
`

// ======================================================================
// DEFAULT LABELS
// ======================================================================

const customLink = props => `
  customLink
`
const pageLink = props => `
  pageLink
`
const imageLink = props => `
  imageLink
`
const documentLink = props => `
  documentLink
`
const articleLink = props => `
  articleLink
`
const videoLink = props => `
  videoLink
`
const locationLink = props => `
  locationLink
`
const eventLink = props => `
  eventLink
`
const environmentLink = props => `
  environmentLink
`
const entityLink = props => `
  entityLink
`
const taxonLink = props => `
  taxonLink
`
const programLink = props => `
  programLink
`
const municipalityLink = props => `
  municipalityLink
`

// ======================================================================
// DEFAULT
// ======================================================================

const defaultPages = props => `
  defaultPages {
    ${homePage({ ...props })}
    ${documentsPage({ ...props })}
    ${articlesPage({ ...props })}
    ${locationsPage({ ...props })}
    ${eventsPage({ ...props })}
    ${entitiesPage({ ...props })}
    ${environmentsPage({ ...props })}
    ${taxonsPage({ ...props })}
    ${programsPage({ ...props })}
    ${municipalitiesPage({ ...props })}
  }
`

const defaultLabels = props => `
  defaultLabels {
    ${customLink({ ...props })}
    ${pageLink({ ...props })}
    ${imageLink({ ...props })}
    ${documentLink({ ...props })}
    ${articleLink({ ...props })}
    ${videoLink({ ...props })}
    ${locationLink({ ...props })}
    ${eventLink({ ...props })}
    ${environmentLink({ ...props })}
    ${entityLink({ ...props })}
    ${taxonLink({ ...props })}
    ${programLink({ ...props })}
    ${municipalityLink({ ...props })}
  }
`

const strapi = props => `
  site {
    title
    author
    description
    url
    language
    ${defaultPages({ ...props })}
    ${defaultLabels({ ...props })}
  }
  ${pages({ ...props })}
  ${documents({ ...props, nested: 2 })}
  ${articles({ ...props, nested: 2 })}
  ${locations({ ...props, nested: 2 })}
  ${events({ ...props, nested: 2 })}
  ${entities({ ...props, nested: 2 })}
  ${environments({ ...props, nested: 2 })}
  ${taxons({ ...props, nested: 2 })}
  ${programs({ ...props, nested: 2 })}
  ${municipalities({ ...props, nested: 2 })}
`

// ======================================================================
// OUTPUTS EXPORTS
// ======================================================================
export const getSiteQuerySchema = props => `
  {
    site {
      ${defaultPages({ ...props })}
      ${defaultLabels({ ...props })}
    }
  }
`

export const getPageQuerySchema = props => `
  {
    site {
      ${defaultPages({ ...props })}
      ${defaultLabels({ ...props })}
    }
    page(id: "${props.pageId}") {
      ${page({ ...props })}
      parentPages {
        ${page({ ...props })}
        parentPages {
          ${page({ ...props })}
        }
      }
      ${flexibleContent({ ...props })}
    }
  }
`

export const getStrapiQuerySchema = props => `
  {
    ${
      props.queryBy === "gatsby"
        ? `
          strapi {
            ${strapi({ ...props })}
          }
        `
        : "#undefined"
    }
    ${
      props.queryBy === "apollo"
        ? `
          ${strapi({ ...props })}
        `
        : "#undefined"
    }
  }
`

export const getMapQuerySchema = props => `
  {
    strapi {
      ${locations({ ...props, nested: 2 })}
      ${events({ ...props, nested: 2 })}
      ${taxons({ ...props, nested: 2 })}
      ${environments({ ...props, nested: 2 })}
      ${programs({ ...props, nested: 2 })}
      ${entities({ ...props, nested: 2 })}
      ${municipalities({ ...props })}
      ${departments({ ...props })}
    }
  }
`
